.oooh-baby-regular {
  font-family: "Oooh Baby", cursive;
  font-weight: 400;
  font-style: normal;
}

.maxh50 {
  max-height: 40svh;
}

.maxh20 {
  max-height: 22svh;
}